import React, { useState } from "react";
import ItemViewMarkdown from "./ItemViewTabs/ItemViewMarkdown";
import ItemViewHTML from "./ItemViewTabs/ItemViewHTML";
import ItemViewList from "./ItemViewTabs/ItemViewList";
import ItemViewKeyInformation from "./ItemViewTabs/ItemViewKeyInformation";
import ItemViewGallery from "./ItemViewTabs/ItemViewGallery";
export default function ItemTabs({ data }) {
  const [selectedTab, setSelectedTab] = useState(1);


  const updateSelectedTab = (tab) => {


    setSelectedTab(tab);
  }
  return (
    <div>
    <div className="item-view-tab-nav">
   <div
        className={
          selectedTab == 0
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(0)}
      >
        Key Information
      </div> 

      { data.statistics !==  "" ? <div
        className={
          selectedTab == 1
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(1)}
      >
        Statistics
      </div> : null }

      { data.did_you_know !==  "" ? <div
        className={
          selectedTab == 2
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(2)}
      >
        Did you Know?
      </div> : null }

      { data.experience !==  "" ? <div
        className={
          selectedTab == 3
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(3)}
      >
        Experience
      </div> : null }
      { data.history !==  "" ? <div
        className={
          selectedTab == 4
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(4)}
      >
        History
      </div> : null }
      
       { data.related_items.length > 0 ? <div
        className={
          selectedTab == 5
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(5)}
      >
        Related Items
      </div> : null }

       <div
        className={
          selectedTab == 6
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(6)}
      >
        Gallery
      </div> 
    </div>

    <ItemViewKeyInformation tabId={0} selectedTab={selectedTab} data={data} />
    <ItemViewList tabId={5} selectedTab={selectedTab} data={data} />
    <ItemViewMarkdown tabId={4} selectedTab={selectedTab} data={data.history} />
    <ItemViewMarkdown tabId={3} selectedTab={selectedTab} data={data.experience} />
    <ItemViewMarkdown tabId={2} selectedTab={selectedTab} data={data.did_you_know} />
    <ItemViewHTML tabId={1} selectedTab={selectedTab} data={data.statistics} />
    <ItemViewGallery tabId={6} selectedTab={selectedTab}/>
</div>
  );
}
