import React, {useState, useEffect} from "react";




export default function ViewonQueueTimeHub({id, parkid}){

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {

      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {

        const response = await fetch('https://attractionsbase.com/api/get-qth-id/?parkid=' + parkid + '&id='+id);
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true)
      }
    };


return (


!loading && data !== null ? 
<div className="view-on-queue-time-hub-container">
<a  className="view-on-qth-inner" href={"https://queuetimehub.com/appnew/#/info/" + parkid + "/" + data + "/"}>


<img className="view-on-qth-img" src="https://queuetimehub.com/appnew/images/icons/icon-512x512.png"></img>
<span className="view-on-qth-text">Queue Time Hub</span>


</a>
</div>

: null 



)


}