import React, {useState, useEffect} from 'react'
import HeaderWithImage from '../BasicHomePage/HeaderWithImage'
import CountUp from 'react-countup';
import HomePageNumber from './HomePageNumber';
import HomePageContent from './HomePageContent';
import NewsArticleItem from '../News/NewsArticleItem';
import GallerywData from '../Gallery/GalleryWData';
import { useMediaQuery } from 'react-responsive';
import PageLoader from '../PageLoader';
export default function HomePage(){



    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
        const [loading, setLoading] = useState(true);
        useEffect(() => {
          fetchData();
        }, []);
      
        const fetchData = async () => {
          try {
            const response = await fetch('https://attractionsbase.com/api/home/');
            const jsonData = await response.json();
            setData(jsonData);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setError(true);
          }
        };



return (

  

 !loading ? 
  <div className="home-page-container">
  {/* <GallerywData  GalleryWidth='100vw' GalleryHeight={isMobile ? '300px' : '600px'}/> */} 
<div className='home-page-numbers'>

   

        <HomePageNumber label="Attractions" number={data.settings.num_attractions} />
        <HomePageNumber label="Parks" number={data.settings.num_parks} />
        <HomePageNumber label="News Articles" number={data.settings.num_articles} />
    
</div>



<HomePageContent image={data.settings.content_image} text={data.settings.introduction} />
<div className='news-article-home-page-title'>Latest News Articles</div>

<div className='news-article-home-page'>

<NewsArticleItem data={data.settings.news[0]} />

</div>
</div>
: <PageLoader />

    
)




}