import React, { useState } from 'react'
import MobileMenuDropDownHeader from './MobileDropDownHeader'
import MobileMenuDropDownGroup from './MobileMenuDropDownGroup'
import { AnimatePresence, motion } from 'framer-motion'

export default function MobileMenuDropDown({item, setOpen}){

const [dropdownOpen, setdropdownOpen] = useState(false)




    return (
    
        <>
        <div className="mobile-menu-dropdown">

         <MobileMenuDropDownHeader data={item.menuTitle} dropdownOpen={dropdownOpen} setdropdownOpen={setdropdownOpen} />
         <AnimatePresence mode="wait" initial={false}>
       { dropdownOpen ? 
       
       <motion.div
              {...framerDropDowns}
              
            >
       <MobileMenuDropDownGroup setOpen={setOpen} data={item} /> </motion.div>: null }

       

       
</AnimatePresence>
        </div>
</>

    )
}



const framerDropDowns = {
    initial: { x: '-100%' },
    animate: { x: 0 },
    exit: { x: '-100%' },
    transition: { duration: 0.3 },
  }
  