import React, {useState} from 'react';
import GalleryWData from '../Gallery/GalleryWData'
import GalleryNew from '../Gallery/GalleryNew';

export default function ItemImage({data}){
    const [imageLoaded, setImageLoaded] = useState(false)


    return (
    <div className="item-image">
    { imageLoaded === false ? <span className='image-placeholder large-image-lazy-load'></span> : null}
    
 { data.is_gallery === true ? <GalleryNew data={data.img_url}  loading={setImageLoaded} GalleryWidth={525} GalleryHeight={350}/> :   <img className="item-img" style={imageLoaded ? {} : {display:'none'}} onLoad={() => setImageLoaded(true)} src={"https://attractionsbase.com/" + data.img_url} />}


  
    </div>
    )

}