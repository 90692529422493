import React from "react";
import FerrisWheel from "./FerrisWheel";


export default function PageLoader({isError}) {




return (

<div className="page-loader">
   

{isError ? <><span className="loading-error">An error occured whilst requesting the data! Please try again.</span></> :  <><FerrisWheel /></> }
</div>

)




}