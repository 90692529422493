import React, { useState, useEffect } from "react";
import ItemListViewImage from "./ListItemViewImage";
import {NavLink} from "react-router-dom"

import {AnimatePresence, motion} from 'framer-motion'
export default function ItemListViewItem({data, displayCSS, type}){

const [finalUrl, setfinalUrl ] = useState("../item/" + data.id)
useEffect(() => {
if (type === "area"){

  setfinalUrl("../area/" + data.id)
}

},[])

return (
<AnimatePresence >
  
  <motion.div style={{display: displayCSS}}
          exit={{ opacity: 0 }}
        >
<NavLink  to={finalUrl} >
<div className="item-list-view-card">

<ItemListViewImage img={data.img_url} />
<div className="item-list-view-text-container">


    {data.name.length <= 10 ? <div className="item-list-view-card-title">{data.name}</div> : <div className="item-list-view-card-title">{data.short_name}</div>}

{type !== "area" ? <div className="item-list-view-card-sub-title"><i className="fa-solid fa-location-dot" aria-hidden="true"></i> {data.area}</div> : null }
{data.status === "open" && data.status_message === "" && data.queue_id !== "" ? <div className="item-list-view-card-queuetime"><i className="icon white fa-solid fa-stopwatch" aria-hidden="true"></i> <span className={data.color}>{data.time} mins</span></div> : null} 
{data.status === "" && data.status_message !== "" && data.queue_id !== "" ?  <div className="item-list-view-card-queuetime"><i className="icon white fa-solid fa-stopwatch" aria-hidden="true"></i> <span className={data.color}>{data.status_message}</span></div> : null} 
{data.status !== "open" && data.status_message === "" && data.queue_id !== "" ? <div className="item-list-view-card-queuetime"><i className="icon white fa-solid fa-stopwatch" aria-hidden="true"></i> <span className={data.color}>{data.status}</span></div> : null} 
{data.next_show !== null && data.next_show !== "" ? <div className="item-list-view-card-queuetime"><i class="fa-solid fa-masks-theater"></i> {data.next_show}</div> : null} 
</div>

</div>
</NavLink>
</motion.div>
</AnimatePresence>
)


}