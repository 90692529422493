import React from 'react'



export default function ListViewEnd({data}) {





 return (
 
 <>
 
 
 <div className='list-view-item-end'>{data.end}</div>
 
 </>
 
)




}