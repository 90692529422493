
import React, {useState} from "react";
import MobileMenuNavBar from "./MobileMenuNavBar";
import MobileMenuBody from "./MobileMenuBody";
export default function MobileMenu(){


    const [isOpen, setOpen] = useState(false)



    


return(

<>
 <MobileMenuNavBar isOpen={isOpen} setOpen={setOpen} />

<MobileMenuBody isOpen={isOpen}  setOpen={setOpen} />
</>


)




}