import React from 'react'

export default function ListViewDoubleText({data}){

return (

<div className='list-view-item-stacked-title'>
    <span className='list-view-item-stacked-title-primary'>{data.name}</span>
<span className='list-view-item-stacked-title-secondary'>{data.subTitle}</span>

</div>


)



}