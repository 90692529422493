import React from "react";
import { Link } from "react-router-dom";
export default function NewsArticleNavigationButton({number}){


return (
    
<Link to={'/news/' + number}>
    <span className="news-article-nav-button">{number}
    </span>
    </Link>
   
)


}