import React from 'react'


export default function ItemViewHTML({selectedTab, tabId, data}) {

return (

<span className={selectedTab == tabId ? "show-item-content item-stats" : "hide-item-content item-stats"}  dangerouslySetInnerHTML={{__html: data}}></span>


)


}