
import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  import ItemListViewItemArea from './ItemListViewItemArea';
import AnimatedPage  from '../AnimatedPages';
import {AnimatePresence, motion} from 'framer-motion'
import ItemListViewButtons from './ItemListViewButtons';
import PageLoader from '../PageLoader';



export default function ItemListViewTabs({selectedTab, tabId, incomingData}) {
    const { itemId, type, parkId } = useParams()
    const [data, setData] = useState([]);
    const [urlsuffix, setUrlsuffix] = useState(type);
    const [filter, setFilter] = useState("*");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {

      setData(incomingData);
      setLoading(false)
    }, []);
  
    
    
  
    
return (
    <span className={selectedTab == tabId ? "show-item-content item-listview" : "hide-item-content item-listview"}>
<AnimatedPage>
{loading ? <PageLoader isError={error} /> : <div className="item-list-view">
   
  
  <div className="item-list-view-container">

{data.map(function(item,index){
return (<>
<motion.div  layout animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>


    {filter === item.area || filter === "*" ? <ItemListViewItemArea key={index} data={item}/> : null}
 
   </motion.div>

   </>
)
})
}

</div></div>
}
</AnimatedPage>
</span>


)







}