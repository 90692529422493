import React from "react";
import { Link } from "react-router-dom";
export default function MobileMenuImg({src}){


return (

<Link to="/">
<div className="mobile-nav-image">

    <img className="mobile-nav-img" src={src}></img>
</div>
</Link>
)









    
}