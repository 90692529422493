import React from 'react'
import { Link } from 'react-router-dom'


export default function MobileMenuDropDownGroup({data,setOpen}){



    return (
       
         <div className='mobile-menu-dropdown-body'>


{ data.items.map((item, index) => (
                 <Link to={item.url} onClick={()=>setOpen(false)}> <div key={index} className='mobile-menu-dropdown-body-item'>{item.title}</div></Link>
                )) }




         </div>
    )
}