import React from "react";
import MobileMenuImg from "./MobileMenuImg";
import Hamburger from "hamburger-react";


export default function MobileMenuNavBar({isOpen, setOpen}){



return (


    <div className="mobile-menu-navbar">
    <MobileMenuImg src="https://attractionsbase.com/final/media/assets/logo.jpg" />
    <div className="menu-toggle" onClick={() => setOpen(!isOpen)}>
    <Hamburger toggled={isOpen}/> <span className="button-label">Menu</span>
    </div>
    </div>
)





}