import React from 'react';


export default function ItemKeyInfo({data}){

    return (


        <span className="item-key-information-container">
                 <span className="item-key-information-inner"><span className="item-key-information-icon"><i className="fa-solid fa-camera" aria-hidden="true"></i></span>{ data.on_ride_photo  === "1"? "Yes" : "No"}</span>
                 <span className="item-key-information-inner"><span className="item-key-information-icon"><i className="fa-solid fa-shop" aria-hidden="true"></i></span>{ data.queue_line_shop === "1" ? "Yes" : "No"}</span>
                 <span className="item-key-information-inner ng-binding"><span className="item-key-information-icon"><i className="fa-solid fa-users" aria-hidden="true"></i></span>{ data.fastrack === "1" ? "Yes" : "No"}</span>
                 <span className="item-key-information-inner ng-binding"> <span className="item-key-information-icon"><i className="fa-brands fa-accessible-icon" aria-hidden="true"></i></span>{ data.fastrack === "1" ? "Yes" : "No"}</span>
                 
                 
                 </span>

    )

}