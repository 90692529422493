import React from "react";
import ItemListViewImage from "./ListItemViewImage";
import {NavLink} from "react-router-dom"

import {AnimatePresence, motion} from 'framer-motion'
export default function ItemListViewItemArea({data, displayCSS}){



return (
<AnimatePresence >
  
  <motion.div style={{display: displayCSS}}
          exit={{ opacity: 0 }}
        >
<NavLink  to={"../parks/"+ data.park_id  + "/item/" + data.id} >
<div className="item-list-view-card">

<ItemListViewImage img={data.img_url} />
<div className="item-list-view-text-container">


    {data.name.length <= 10 ? <div className="item-list-view-card-title">{data.name}</div> : <div className="item-list-view-card-title">{data.short_name}</div>}

{data.status === "open" && data.status_message === "" && data.queue_id !== "" ? <div className="item-list-view-card-queuetime"><i className="icon white fa-solid fa-stopwatch" aria-hidden="true"></i> <span className={data.color}>{data.time} mins</span></div> : null} 
{data.status === "" && data.status_message !== "" && data.queue_id !== "" ?  <div className="item-list-view-card-queuetime"><i className="icon white fa-solid fa-stopwatch" aria-hidden="true"></i> <span className={data.color}>{data.status_message}</span></div> : null} 
{data.status !== "open" && data.status_message === "" && data.queue_id !== "" ? <div className="item-list-view-card-queuetime"><i className="icon white fa-solid fa-stopwatch" aria-hidden="true"></i> <span className={data.color}>{data.status}</span></div> : null} 
</div>

</div>
</NavLink>
</motion.div>
</AnimatePresence>
)


}