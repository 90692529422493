import React from 'react'

export default function HomePageContent({text, image}){


return (

    
<div className="home-page-content">
<div className='home-page-content-text'>{text}</div>
<img className='home-page-content-image' src={image}/>


</div>


)




}