import React from 'react'
import ItemImage from '../ItemView/ItemImage';

import ItemDescription from '../ItemView/ItemDescription';
import ListView from '../ListView/ListView';



export default function AreaLeft({data}){

    return (
<div className="item-desktop-left">
        <ItemImage data={data[0]} />
        <h1 className="item-title">{data[0].name}</h1>
        <span className="item-basic-info-inner area-view"><i className="fa-solid fa-circle-info"></i> {data[0].short_description}</span>
<ItemDescription description={data[0].description}/>
<h3>Previous Areas</h3>
<ListView data={data.past_areas} type="past_area"/>
</div>
    )



}