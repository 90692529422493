import React from "react";


export default function ItemGalleryFullScreenItem({data, handleClick, index, finalindex}){



return (


finalindex === index  ? 
    <div className="item-gallery-item-fullscreen selected-gallery-item" onClick={() => handleClick(index)}>
    <img src={data.img_url} className="item-gallery-item-image-fullscreen"></img>
       
   
        
    </div>

    : <div className="item-gallery-item-fullscreen" onClick={() => handleClick(index)}>
    <img src={data.img_url} className="item-gallery-item-image-fullscreen"></img>
       
   
        
    </div> 
)


}