import React from 'react'
import Markdown from 'react-markdown'

export default function ItemViewMarkdown({selectedTab, tabId, data}) {



return (

<div className={selectedTab == tabId ? "show-item-content" : "hide-item-content"}>

<Markdown>{data}</Markdown>

</div>
)

}