import React from "react";


export default function ItemShowTimes({data}){

return (
    
    data !== null ?
    
    <>
    <div className="item-show-show-times-title">Show Times</div>
<div className="item-view-show-times-container">
   

{data.map(function(showtime, index){

return showtime.expired !== true ?<div index={index} className="item-view-show-time-item">{showtime.time}</div> : <div index={index} className="item-view-show-time-item expired">{showtime.time}</div> 


})}

</div>
</>



    : null

)
}