import React from 'react'



export default function ItemViewKeyInformation({selectedTab, tabId,data}) {



return (
<div className={selectedTab == tabId ? " ride-key-information-global show-item-content" : " ride-key-information-global hide-item-content"}>

<span className="ride-key-information">
      <h2 className="key-information list-title">Key Information</h2>
     
        <ul className="list key-information">
            
              { data.height_min !== "0.00" ?   <li className="ride-key-information-container">
          <a className="ride-key-information-inner">
                <span className="ride-key-information-title">Minimum Height</span>
                      <span className="ride-key-information-end">
              <span className="list-item-information ng-binding">{data.height_min}m</span>
              <span className="list-item-action">
               
              </span>
            </span>
          </a>
        </li> : null }
        
        
        { data.height_unaccompanied !== "0.00" ?   <li className="ride-key-information-container ng-hide">
          <a className="ride-key-information-inner" data-parkapp="true">
                <span className="ride-key-information-title">Minimum Height Unacompanied</span>
                      <span className="ride-key-information-end">
              <span className="list-item-information ng-binding">{data.height_unaccompanied}m</span>
              <span className="list-item-action">
               
              </span>
            </span>
          </a>
        </li> : null }
        
        { data.height_max !== "0.00" ? <li className="ride-key-information-container ng-hide">
          <a className="ride-key-information-inner" data-parkapp="true">
                <span className="ride-key-information-title">Maximum Height </span>
                      <span className="ride-key-information-end">
              <span className="list-item-information ng-binding">{data.height_max}m</span>
              <span className="list-item-action">
               
              </span>
            </span>
          </a>
        </li> : null }
        
        
        { data.chest_max !== "0" ?  <li className="ride-key-information-container">
          <a className="ride-key-information-inner" data-parkapp="true">
                <span className="ride-key-information-title">Maximum Chest</span>
                      <span className="ride-key-information-end">
              <span className="list-item-information ng-binding">{data.chest_max}"</span>
              <span className="list-item-action">
               
              </span>
            </span>
          </a>
        </li> : null }
        
        
        { data.age_min !== "0" ?   <li className="ride-key-information-container ng-hide">
          <a className="ride-key-information-inner" data-parkapp="true">
                <span className="ride-key-information-title">Minimum Age</span>
                      <span className="ride-key-information-end">
              <span className="list-item-information ng-binding">{data.age_min}</span>
              <span className="list-item-action">
               
              </span>
            </span>
          </a>
        </li> : null }
        
        
      </ul>
      
      
  </span>


  <span className="ride-key-information">

  <h2 className="key-information list-title">Accessibility</h2>
     
     <ul className="list key-information">
         
             {data.ambulant_ride_access === "1" ? <li className="ride-key-information-container">
       <a className="ride-key-information-inner" data-parkapp="true">
             <span className="ride-key-information-title">Entrance</span>
                   <span className="ride-key-information-end">
           <span className="list-item-information rap ng-binding">{data.rap_entrance}</span>
           <span className="list-item-action">
            
           </span>
         </span>
       </a>
     </li> : null }
     
     
        
     {data.non_ambulant_ride_access === "1" ? <li className="ride-key-information-container">
       <a className="ride-key-information-inner" data-parkapp="true">
             <span className="ride-key-information-title">Non Ambulant Entrance</span>
                   <span className="ride-key-information-end">
           <span className="list-item-information rap ng-binding">{data.non_ambulant_rap_entrance}</span>
           <span className="list-item-action">
            
           </span>
         </span>
       </a>
     </li> : null }
     
     
     {data.flashing_warning === "1" ?   <li className="ride-key-information-container">
       <a className="ride-key-information-inner" data-parkapp="true">
             <span className="ride-key-information-title">Strobe Lighting Used</span>
                   <span className="ride-key-information-end">
           <span className="list-item-information">Yes</span>
           <span className="list-item-action">
            
           </span>
         </span>
       </a>
     </li> : null }
     
      
     
   </ul>

</span>

</div>



)



}