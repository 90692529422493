import React from 'react'


export default function GalleryDots({images, currentImageIndex, dotColor, handleDotClick}){




    return (


<div className="gallery-dots">
        {images.map((_, index) => (
          <span
            style={{ backgroundColor: dotColor}}
            key={index}
            className={`gallery-dot ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>



    )
}