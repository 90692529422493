import React, {useRef, useState, useEffect} from "react";
import MobileMenuDropDown from "./MobileMenuDropDown";
import MobileMenuDropdownItems from "./MobileMenuDropdownItems";
import { useClickAway } from 'react-use'
import { AnimatePresence, motion } from 'framer-motion'
export default function MobileMenuBody({isOpen, setOpen}){



    const ref = useRef(null)
    useClickAway(ref, () => setOpen(false))




    const [data, setData] = useState([]);
const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await fetch('https://attractionsbase.com/api/menu/');
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      }
    };












return (
    <AnimatePresence mode="wait" initial={false}>
 { isOpen ? 
 
 <>
 
 <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
            ></motion.div>
            <motion.div
              {...framerSidebarPanel}
              className="fixed top-0 bottom-0 left-0 z-50 w-full h-screen max-w-xs border-r-2 border-zinc-800 bg-zinc-900"
              ref={ref}
              aria-label="Sidebar"
            >
 
 
 
 
 
 
 
 <div className="mobile-menu-main">


 { data.map((item, index) => (
                  <MobileMenuDropDown setOpen={setOpen} key={index} item={item} />
                )) }

<MobileMenuDropdownItems/> 


    
</div>   </motion.div> </>: null }


</AnimatePresence>

)






}



const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  }
  
  const framerSidebarPanel = {
    initial: { x: '-100%' },
    animate: { x: 0 },
    exit: { x: '-100%' },
    transition: { duration: 0.3 },
  }
  
  const framerText = delay => {
    return {
      initial: { opacity: 0, x: -50 },
      animate: { opacity: 1, x: 0 },
      transition: {
        delay: 0.5 + delay / 10,
      },
    }
  }