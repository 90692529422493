import React from 'react'
import FooterSocialMediaButtons from './FooterSocialMediaButtons'


export default function Footer(){




return (


    <div className='footer'>




    <p className='disclaimer'>All brands and logos remain the property of their legal owner. No copyright infrigement intended. <a target="_blank" href="https://icons8.com/icon/aaoukT8evpOE/tiktok">Social Media</a> icons by <a target="_blank" href="https://icons8.com">Icons8</a></p>
    <div className='social-buttons'>
        
        <FooterSocialMediaButtons url="https://x.com/attractionsbase" img="https://attractionsbase.com/icons/twitter.png"/>
        <FooterSocialMediaButtons url="https://instagram.com/attractionsbase" img="https://attractionsbase.com/icons/instagram.png"/>
        <FooterSocialMediaButtons url="https://tiktok.com/attractionsbase" img="https://attractionsbase.com/icons/tiktok.png"/>
        <FooterSocialMediaButtons url="https://facebook.com/attractionsbase" img="https://attractionsbase.com/icons/facebook.png"/>
        </div>   
    </div>
)


}