import React, {useState, useEffect} from "react";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";
export default function NewsArticle(){



    const { itemId } = useParams()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {

      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {

        const response = await fetch('https://attractionsbase.com/api/news/?id='+itemId);
        const jsonData = await response.json();
        setData(jsonData[0]);
        setLoading(false);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true)
      }
    };

return (

<>
<div className="news-article-header">    
</div>



<div className="news-article-info-container">

<div className="news-article-large-title">{data.title}</div>
<div className="news-article-author">{data.author}</div>
<div className="news-article-published-date">{data.pretty_date}</div>
</div>

<div className="news-article-content">
<Markdown>{data.content}
</Markdown>
</div>


</>
)




}