import React from "react";


export default function ItemGalleryItem({data, handleClick, itemIndex}){



return (



    <div className="item-gallery-item" onClick={() =>handleClick(itemIndex)}>
    <img src={data.img_url} className="item-gallery-item-image"></img>
    <div className="item-gallery-description-container">
    <span className="item-gallery-description-title">{data.title}</span>
        
    </div>

        
    </div>
)


}