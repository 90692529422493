import React from 'react'
import ListViewItem from './ListViewItem'
import ListViewItemNoImage from './ListViewItemNoImage'
export default function ListView({data, type}) {

return (
    <>
    
<div className='list-view-container'>
{
 data.map(function(item, i){



return <ListViewItem customUrl={"../item/" + item.id} type={type} key={i} data={item} />


 }





)



}
</div>



</>
)

}