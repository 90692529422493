import './index.css';
import './modules/css/style.scss'
import './modules/css/ferriswheel.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import logo from './logo.svg';
import './App.css';
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Router } from 'react-router-dom';
  
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
     <BrowserRouter>
      <App />
      </BrowserRouter>
    </React.StrictMode>
  );





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
