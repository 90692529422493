import React from "react";
import ListViewBNested from "../ListView/ListViewNested";

export default function ParkLandingLeft({data, parkId}){




return (
    <div className="park-landing-left">
<ListViewBNested parkid={parkId} data={data.items} />
</div>
)

}