import React from 'react'
import AreaTabs from './AreaTabs'

export default function AreaRight({data}){

    return (
<div className="item-view-right">
        <AreaTabs data={data} />
</div>
    )



}