import React, {useState} from 'react'


export default function  GalleryItemNew({data}) {
    const [imageLoaded, setImageLoaded] = useState(false)

return (
<>
    <div className='gallery-item'  style={{backgroundImage: "url(" +data + ")"}}>
       
    </div>
    </>
)


}