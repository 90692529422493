import React, {useState, useEffect} from "react";
import { GetData } from "../GetData";
import { useParams } from "react-router-dom";
import ItemGalleryItem from "./ItemGalleryItem";
import ItemGalleryFullScreenView from "./ItemGalleryFullScreenView";


export default function ItemGallery(){
    const { itemId } = useParams()

   const [fullscreen, setFullscreen] = useState(false);
   const [fullscreenindex, setFullscreenIndex] = useState(0);
    const [data, setData] = useState([]);
const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await fetch('https://attractionsbase.com/api/gallery-item/?id=' + itemId);
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      }
    };



    const closeFullScreen = (boolean) => {
        document.body.classList.remove('no-scroll');
        setFullscreen(false)
    }


const handleClick = (id) => {

window.scrollTo(0,0);
setFullscreenIndex(id);
setFullscreen(true);
document.body.classList.add('no-scroll');

}


return (


<>

{fullscreen === true ? <ItemGalleryFullScreenView closeFullScreen={closeFullScreen} data={data} index={fullscreenindex}  /> : null}

    <div className="item-gallery-container">

    {data.map(function(item, index){

        return <ItemGalleryItem data={item} handleClick={handleClick} itemIndex={index} index={index}/>

    })}

{data.length === 0 ? <span>There are no items</span> : null }
    </div>
    </>
)


}