import React, {useState, useEffect} from "react";
import HeaderWithImage from '../BasicHomePage/HeaderWithImage'
import { useParams } from "react-router-dom";
import ListViewNested from "../ListView/ListViewNested";
import PageLoader from "../PageLoader";
import ParkLandingLeft from "./ParkLandingLeft";
import ParkLandingRight from "./ParkLandingRight";
export default function ParkLanding(){

    const { parkId } = useParams()
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("*");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await fetch('https://attractionsbase.com/api/app/?id=' + parkId);
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
       // document.title = data.park_name + " | AttractionsBase";
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true)
      }
    };



return (

loading ? <PageLoader isError={error} />: 


<>
<HeaderWithImage title={data.park_name} src={data.header_img} />
<div className="park-landing-container">
<ParkLandingLeft parkId={parkId} data={data} />
<ParkLandingRight />
</div>
</>
)







}