import React from 'react';
import { NavLink } from 'react-router-dom';

export default function ItemBasicInfo({data}){

    return (

<>

<h1 className="item-title">{data.name}</h1>
<span className="item-basic-info">

<span className="item-basic-info-inner"><i className="fa-solid fa-location-dot" aria-hidden="true"></i><NavLink to= {"/area/" + data.area_id}> {data.area}</NavLink></span>
{ data.queue_id !== null ? <span className={"item-basic-info-inner " }><i className="icon white fa-solid fa-stopwatch" aria-hidden="true"></i> <span className={data.color}>{data.status}</span></span> : null}

</span>
</>


    )
}