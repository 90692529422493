
import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import ItemListViewItemArea from './ItemListViewItemArea';
import AnimatedPage  from '../AnimatedPages';
import {AnimatePresence, motion} from 'framer-motion'
import ItemListViewButtons from './ItemListViewButtons';
import PageLoader from '../PageLoader';



export default function ItemListViewPOI({selectedTab, tabId, incomingData}) {
    const { itemId, type, parkId } = useParams()
    const [data, setData] = useState([]);
    const [urlsuffix, setUrlsuffix] = useState(type);
    const [filter, setFilter] = useState("*");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {

      setData(incomingData);
      setLoading(false)
    }, []);
  
    
    
  
    
return (
    <span className={selectedTab == tabId ? "show-item-content item-listview" : "hide-item-content item-listview"}>
<AnimatedPage>
{loading ? <PageLoader isError={error} /> : <div className="item-list-view">
   
  
  <div className="item-list-view-container">

{data.map(function(item,index){
return (<div key={index}>

<div className='area-view-poi-view'>
<span className='area-view-poi-view-title'>{item.name}</span>


{item.poi.map(function(poi, index){
    return <ItemListViewItemArea key={index} data={poi}/> 
 
})
}
</div>
   </div>
)
})
}

</div></div>
}
</AnimatedPage>
</span>


)







}