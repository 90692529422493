import React from 'react'
import ListViewItem from './ListViewItem'
import ListViewItemNoImage from './ListViewItemNoImage'
export default function ListViewBNested({data}) {

return (
<div className='list-view-container'>
    
{
 data.map(function(item, i){
    
    
    return (
        <div key={i}>
    <h2>{item.name}</h2>

    {item.name === "Explore" ? <ListViewItem type="areas" data={item} url="areas/"/> : null }
{item.item.map(function(nestedItem, nestedIndex){

return  <ListViewItem url="items/" key={nestedIndex} data={nestedItem} />
 })
 }
</div>
)
 }


)

}
</div>
)

}