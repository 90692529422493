import React from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
export default function  NewsArticleItem({data}){



return (


<div className="news-article-item">
<div className="news-article-image">
<img src={data.img_url} className="news-article-img" />
</div>
<div className="news-article-item-inner">
<div className="news-article-title">{data.title}</div>
<div className="news-article-description"><Markdown>{data.content}</Markdown></div>
<div className="button-container-news">
    <Link to={'/news-article/' + data.id }>
<div className="button-news">Read</div>
</Link>
</div>
</div>
</div>






)




}