import React from "react";


export default function FerrisWheel(){


return (
<div className="ferris-wheel-container">
<div className="ferris_wheel">
  <div className="wheel_wrap">
    <div className="wheel">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div className="buckets">
    <div><span></span></div>
    <div><span></span></div>
    <div><span></span></div>
    <div><span></span></div>
    <div><span></span></div>
    <div><span></span></div>
    <div><span></span></div>
    <div><span></span></div>
  </div>
  <div className="stand"><span></span></div>
</div>
</div>
)

}