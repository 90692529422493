import React, {useState, useEffect} from 'react'
import GalleryItem from './GalleryItem'
import GalleryDots from './GalleryDots';
import GalleryItemNew from './GalleryItemNew';
import { useSwipeable } from 'react-swipeable';
export default function  GalleryNew({data,GalleryWidth,GalleryHeight, loading}) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {

        loading(true);
        
        const interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) =>
            prevIndex === data.length - 1 ? 0 : prevIndex + 1
          );
        }, 10000);
    
        return () => clearInterval(interval);
      }, [data]);
    
      const handleDotClick = (index) => {
        setCurrentImageIndex(index);
      };
      const handlers = useSwipeable({
        onSwipedLeft: () => goForward(),
        onSwipedRight: () => goBack(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
      });

      const goBack = () => {
        setCurrentImageIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex - 1
      );
      }

      const goForward = () => {
        setCurrentImageIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
      }
return (



    data !== null ? <div className='gallery' style={{ width: GalleryWidth ,  height: GalleryHeight}}>



<div className="gallery-image-container" style={{ transform: `translateX(-${currentImageIndex * 100}%)` }} {...handlers}>
        {data.map((image, index) => (
          <GalleryItemNew key={index} data={image} />
        ))}
        <GalleryItemNew newdata={data[0]} />
      </div>
      <GalleryDots images={data} dotColor="#dff6f5" currentImageIndex={currentImageIndex} handleDotClick={handleDotClick} />

</div>

: <p>Something is fucked</p>

)



}