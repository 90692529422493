import React, {useState} from 'react'



export default function ListViewImage({img, handleError}) {


    const [imageLoaded, setImageLoaded] = useState(false)

return (
    <div className="list-view-item-image">
{ imageLoaded === false ? <span className='image-placeholder '></span> : null}
<img style={imageLoaded ? {} : {display:'none'}} onError={() => handleError(true)}  onLoad={() => setImageLoaded(true)} className='list-view-item-image-img' src={"https://attractionsbase.com/" +img} />

</div>
)


}