import React, {useEffect, useState} from "react";
import NewsArticleItem from "./NewsArticleItem";
import { useParams } from "react-router-dom";
import NewsArticleNavigationButton from "./NewsArticleNavigationButton";
import PageLoader from '../PageLoader'
export default function NewsLanding(){

    const { pageId } = useParams()
    const [data, setData] = useState([]);
   
    const [filter, setFilter] = useState("*");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageCount, setPageCount] = useState(0)
    useEffect(() => {

      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {

        const response = await fetch('https://attractionsbase.com/api/news');
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
        setPageCount(Object.keys(jsonData).length)
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true)
      }
    };

    const getNavButtons = () => {
        let content = [];
        for (let i = 0; i < pageCount; i++) {
          content.push(<NewsArticleNavigationButton number={i + 1} />);
        }
        return content;
      };



    return (

        <>
        { !loading ?
        <div className="news-articles-container">
       { data[pageId].map(function(article, index){

 return <NewsArticleItem key={index} data={article} />


       })}
       <div className="news-nav-button-container">
       {getNavButtons()}
       </div>
      
        </div> : <PageLoader /> }
        </>
    )





    
}