import React, {useState, useEffect} from "react";
import ItemListViewButton from "./ItemListViewButton";
import ItemListViewSortButtons from "./ItemListViewSortButtons";
export default function ItemListViewButtons({handleclick, sort, filter, parkId, queuesort}) {


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://attractionsbase.com/api/queuetimeclasses/?id=' + parkId);
      const jsonData = await response.json();
      setData(jsonData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
     
    }
  };



return (
    <div className="item-list-view-button-container">
      <ItemListViewSortButtons queuesort={queuesort} sort={sort} />
    { filter !== "*"? <ItemListViewButton handeClick={handleclick} filterText="All" filterType="*" /> : null }

 { data.map(function(area, index){ 


return <ItemListViewButton key={index} handeClick={handleclick} filterText={area.name} filterType={area.id} />



})
}

</div>

)



}