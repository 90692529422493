import React, { useState } from "react";
import ItemGalleryFullScreenItem from "./ItemGalleryFullScreenItem";
import ItemGalleryCloseItem from "./ItemGalleryCloseItem";
import AnimatedPage from "../AnimatedPages";
import {AnimatePresence, motion} from 'framer-motion'
import { useSwipeable } from 'react-swipeable';


export default function ItemGalleryFullScreenView({data, index, closeFullScreen}){

    const handlers = useSwipeable({
        onSwipedLeft: () => goFoward(),
        onSwipedRight: () => goBack(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
      });



const [finalindex, setfinalindex] = useState(index);
const [controlshown, setControlShown] = useState(false);

const handleClick = (newindex) => {

setfinalindex(newindex)


}

const goFoward = ()=> {

if (finalindex === data.length -1 ){
setfinalindex(0)

} else {
    setfinalindex(finalindex + 1);
}
}

const goBack = ()=> {
if (finalindex === 0){
setfinalindex(data.length -1 )
} else {

    setfinalindex(finalindex - 1);
}}

const showControls = ()=> {

setControlShown(true);

}

const hideControls = () => {

    setControlShown(false);
}

return (

<AnimatedPage>

    <div className="item-gallery-full-view" >
        <div className="mobile-item-gallery-full-screen-close"><span className="mobile-item-gallery-fullscreen-close-button" onClick={()=>closeFullScreen()}><i class="fa-solid fa-xmark"></i></span></div>
<div className="item-gallery-fullscreen-image-container" {...handlers}>
<img src={data[finalindex].img_url} className="item-gallery-fullscreen-image" onMouseOver={()=>showControls()} onMouseLeave={()=>hideControls()}></img>


{controlshown ?
<div onMouseEnter={() =>showControls()}>
<motion.div  layout animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
<div className="item-gallery-fullscreen-back" onClick={()=> goBack()}>
    
<span><i class="fa-solid fa-chevron-left"></i></span>

</div>
<div className="item-gallery-fullscreen-forward" onClick={()=> goFoward()}>
<span><i class="fa-solid fa-chevron-right"></i></span>

</div>
</motion.div>
</div>
: null }
</div>
<div className="item-gallery-fullscreen-description"><div className="item-gallery-fullscreen-description-text">{data[finalindex].content}</div></div>
<div className="item-gallery-controlsinfo-container">


<div className="item-gallery-fullscreen-items">
    <ItemGalleryCloseItem handleClick={closeFullScreen} />
{data.map(function(item, index){

return <ItemGalleryFullScreenItem finalindex={finalindex} handleClick={handleClick} data={item} index={index} />
})}

</div>
</div>
    </div>
    </AnimatedPage>
)


}