import React, { useState } from "react";
import ItemViewMarkdown from "../ItemView/ItemViewTabs/ItemViewMarkdown";
import ItemViewHTML from "../ItemView/ItemViewTabs/ItemViewHTML";
import ItemViewList from "../ItemView/ItemViewTabs/ItemViewList";
import ItemViewKeyInformation from "../ItemView/ItemViewTabs/ItemViewKeyInformation";
import ItemListViewTabs from "../ItemListView/ItemListViewTabs";
import ItemListViewPOI from "../ItemListView/ItemListViewPOI";
export default function AreaTabs({ data }) {
  const [selectedTab, setSelectedTab] = useState(1);


  const updateSelectedTab = (tab) => {


    setSelectedTab(tab);
  }
  return (
    <div>
    <div className="item-view-tab-nav">
   
     { data.poi !==  null ? <div
        className={
          selectedTab == 0
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(0)}
      >
        Rides & Attractions
      </div> : null }

      { data.statistics !==  "" ? <div
        className={
          selectedTab == 1
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(1)}
      >
        Facilities
      </div> : null }

      { data.did_you_know !==  "" ? <div
        className={
          selectedTab == 2
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(2)}
      >
        Past Rides & Attractions
      </div> : null }

      { data.about !==  "" ? <div
        className={
          selectedTab == 3
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(3)}
      >
        About
      </div> : null }
      { data.history !==  "" ? <div
        className={
          selectedTab == 4
            ? "item-view-tab-inner selected"
            : "item-view-tab-inner"
        }
        onClick={() => updateSelectedTab(4)}
      >
        History
      </div> : null }
    
    </div>

   {/* <ItemViewKeyInformation tabId={0} selectedTab={selectedTab} data={data} />
    <ItemViewList tabId={5} selectedTab={selectedTab} data={data} />  */}
    { data.poi !== null ? <ItemListViewPOI tabId={0} selectedTab={selectedTab} incomingData={data.poi} /> : null }
    <ItemListViewTabs tabId={2} selectedTab={selectedTab} incomingData={data.past_poi} />
    <ItemViewHTML tabId={1} selectedTab={selectedTab} data={data[0].past_items} /> 
    <ItemViewMarkdown tabId={4} selectedTab={selectedTab} data={data[0].history} />
    <ItemViewMarkdown tabId={3} selectedTab={selectedTab} data={data[0].about} />
  
</div>
  );
}
   