import React, {useState, useEffect} from "react";

export default function ItemListViewImage({img}){
    const [imageLoaded, setImageLoaded] = useState(false)
    const [error, setError] = useState(false);
const fallbackimage = "final/media/images/rides/4505.jpg";

    const handleImageError = () => {
     
        setError(true);
        
      };
    
      const handleImageLoad = () => {
        setImageLoaded(true)
      
      };
return (


    <>
    { imageLoaded === false && error === false ? <span className='image-placeholder-square-container'><span className='image-placeholder-square '></span></span> : null}
    { error ? <img className="item-list-view-image" src={"https://attractionsbase.com/" + fallbackimage} /> : null }
    
    <img style={imageLoaded && error === false ? {} : {display:'none'}} onLoad={handleImageLoad}  onError={handleImageError}  className="item-list-view-image" src={"https://attractionsbase.com/" + img} />
    </>
)



    
}