
import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import ItemListViewItem from './ItemlistViewItem';
import AnimatedPage  from '../AnimatedPages';
import {AnimatePresence, motion} from 'framer-motion'
import ItemListViewButtons from './ItemListViewButtons';
import PageLoader from '../PageLoader';



export default function ItemListView() {
    const { itemId, type, parkId } = useParams()
    const [data, setData] = useState([]);
    const [urlsuffix, setUrlsuffix] = useState(type);
    const [filter, setFilter] = useState("*");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {

      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {

        let tempid = "";
        if (urlsuffix === "queuetimes"){

          tempid = parkId;
        } else {
          tempid = itemId
        }
        const response = await fetch('https://attractionsbase.com/api/' + urlsuffix + '/?id=' + tempid);
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true)
      }
    };

    const handleFilter = (category) => {
     setFilter(category)
    };



    const sortData = (type) => {

      if (type === 1) {
      const newData = [...data].sort((a, b) => {
        // Assuming the JSON object has a property 'name' to sort alphabetically
        return a.name.localeCompare(b.name);
      });
      setData(newData);
    } else if (type === 2) {


      const newData = [...data].sort((a, b) => {
        // Assuming the JSON object has a property 'name' to sort alphabetically
        return b.name.localeCompare(a.name);
      });
      setData(newData);

    }
  }
    


  
  const sortQueueData = (type) => {

    if (type === 1) {

      setData([...data].sort((a, b) => a.time - b.time));

  } else if (type === 2) {

    setData([...data].sort((a, b) => b.time - a.time));
  

  }
}

  function isInArray(element, index, array) {
    if(element.class_id === parseInt(filter)){
      return true
    }
  }
return (
<AnimatedPage>
{loading ? <PageLoader isError={error} /> : <div className="item-list-view">
  
  { type === "queuetimes" ? <ItemListViewButtons parkId={parkId} filter={filter} sort={sortData} queuesort={sortQueueData} handleclick={handleFilter}/> : null }
  
  
  <div className="item-list-view-container">

{data.map(function(item,index){
return (<>
<motion.div  layout animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>

  {urlsuffix === "queuetimes" ?
    item.queue_classes.some(isInArray) || filter === "*" ? <ItemListViewItem key={index} data={item}/> : null

    : <ItemListViewItem key={index} data={item}/> }
 
   </motion.div>

   </>
)
})
}

</div></div>
}
</AnimatedPage>



)







}