import React, {useState, useEffect} from "react";

import { useParams } from "react-router-dom";
import HeaderWithImage from "../BasicHomePage/HeaderWithImage";

export default function CustomPage(){


    const { pageId } = useParams()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {

      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await fetch('https://attractionsbase.com/api/custompage/?id=' + pageId);
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true)
      }
    };




    

return (
!loading ? 
<>
<HeaderWithImage src={data[0].img_url} title={data[0].app_title} />
<div className="custom-page-container">

<span className="custom-page-html" dangerouslySetInnerHTML={{__html: data[0].content}}></span>

</div>
</>
:null


)







}