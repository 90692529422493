import React  from "react";

export default function ItemListViewButton({handeClick, filterType, filterText}){


return (

<button className="item-list-view-button button-primary" onClick={() => handeClick(filterType)} >{filterText} </button>
)


}