import * as React from "react";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Menu from './modules/Menu/Menu'
import HomePage from './modules/HomePage/HomePage' 
import Video from './Pages/Video'
import ItemView from './modules/ItemView/ItemView';
import ItemListView from './modules/ItemListView/ItemListView';
import ParkLanding from "./modules/ParkLanding/ParkLanding";
import PageLocationIndiciator from "./modules/PageLocationIndicator/PageLocationIndicator";
import { AnimatePresence } from 'framer-motion';
import MobileMenu from "./modules/MobileMenu/MobileMenu";
import NewsLanding from "./modules/News/NewsLanding";
import NewsArticle from "./modules/News/NewsArticle";
import CustomPage from "./modules/CustomPages/CustomPage";
import PageLoader from "./modules/PageLoader";
import Footer from "./modules/Footer/Footer";
import AreaView from "./modules/AreaView/AreaView";
import AreaLanding from "./modules/AreaLanding/AreaLanding";
import NavLocation from "./modules/NavLocation/NavLocation";
function App() {

const location = useLocation();

return (

<>

<MobileMenu />
<div className="main-body">
<AnimatePresence mode="wait">

<Routes key={location.pathname} location={location}>

      <Route path="/" element={<HomePage />} />
      <Route path="/parks/:parkId" element={<ParkLanding />} />
      <Route path="/area/:areaId" element={<AreaView />} />
      <Route path="/areas/:parkId" element={<AreaLanding />} />
      <Route path="/news/:pageId" element={<NewsLanding />} />
      <Route path="/loadertemp/" element={<PageLoader />} />
      <Route path="/custom-page/:pageId" element={<CustomPage />} />
      <Route path="/news-article/:itemId" element={<NewsArticle />} />
      <Route path="parks/:parkId">
        <Route path="items/:type/:itemId" element={<ItemListView />} />
        <Route path="item/:itemId" element={<ItemView />} />
      </Route>
      

</Routes>

</AnimatePresence>
</div>
<Footer />


</>




)

}


export default App;
