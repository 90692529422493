import React from 'react';


export default function ItemOpeningTimes({openingTime, hasOpeningTimes}){
return (

hasOpeningTimes === "1" ?

    <span className="item-opening-times">
    <span className="item-opening-times-inner">
      <span className="item-opening-times-container">
        <span className="item-opening-time-icon">
          <i className="fa-solid fa-clock" aria-hidden="true"></i>
        </span>
        {openingTime.length === 0 ? <span className="item-opening-time-text">Closed Today</span> :

openingTime.map((openingTime, index) => (

         <span key={index} className="item-opening-time-text">{openingTime.open_time} - {openingTime.close_time}</span>

))}

      </span>
    </span>
  </span>

 : null 
)
}