import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";


export default function  ParkLandingRight(){

    const { parkId } = useParams()
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("*");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await fetch('https://attractionsbase.com/api/opening-hours-home/?id=' + parkId);
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
       // document.title = data.park_name + " | AttractionsBase";
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true)
      }
    };

return (

<div className="park-landing-right">

{!loading ?
<>


{data.park.is_alert === "1" ? <div style={{backgroundColor: data.park.alert_color}} className="latest-informationn park-alert">
<div className="latest-information-inner">
{data.park.alert_content}


</div>



</div> : null }



<div className="todays-park-hours-container">
<div className="todays-hours-title">Today's Hours</div>
{data.today === null ? <div className="todays-hours-content">Closed</div> : <div className="todays-hours-content">{data.today.open_time} - {data.today.close_time}</div> }
{ data.today !== null ? data.today.has_extra_hours === "1"  ? <div className="todays-hours-extra-hours"><span className="extra-hours">{data.today.extra_hours_name}: </span>{data.today.extra_hours_open} - {data.today.extra_hours_close}</div> : null : null}



<div className="further-hours-container">
{data.tomorrow !== null ?
<div className="further-hours">
<div className="further-hours-date">Tomorrow</div>
<div className="further-hours-content">{data.tomorrow.open_time} - {data.tomorrow.close_time}</div>
</div>

: null }

{data.dayaftertomorrow !== null ?
<div className="further-hours">
<div className="further-hours-date">{data.dayaftertomorrow.date}</div>
<div className="further-hours-content">{data.dayaftertomorrow.open_time} - {data.dayaftertomorrow.close_time}</div>
</div>
: null }
</div>
</div>

</>
: null }

{data.planned_closures !== null && loading === false ?
<div className="planned-ride-closures">
<div className="planned-closures-title">Planned Ride Closures</div>

<div className="planned-closure-content-container">

    {data.planned_closures.map(function(ride, index){


       return <div className="planned-closure-item">{ride.name}</div>
    })}

</div>

</div> : null }

{loading === false ?

 data.park.description !== "" ?
<div className="latest-information">
<div className="latest-information-inner">


{data.park.description}
</div>



</div>


: null : null }



{loading === false && data.news !== null
? 
<div className="latest-park-news-article">
    
    <div className="latest-park-news-article-image"><img className="latest-park-news-article-img" src={data.news.img_url}></img></div>

    <div className="latest-park-news-article-title">{data.news.title}</div>
<div className="latest-park-news-article-content">
{data.news.content}
</div>
<Link to={'/news-article/' + data.news.id }>
<div className="button-news">Read</div>
</Link>


</div> : null }

</div>

    
)



}