import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  
import Menu from '../Menu/Menu';
import ItemLeft from './ItemLeft';
import ItemRight from './ItemViewRight';
import AnimatedPage from '../AnimatedPages';
import PageLoader from '../PageLoader';
export default function ItemView(){
    const { itemId } = useParams()

   
    const [data, setData] = useState([]);
const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await fetch('https://attractionsbase.com/api/poi/?id=' + itemId);
        const jsonData = await response.json();
        setData(jsonData[0]);
        setLoading(false);
        document.title = jsonData[0].name +  " | AttractionsBase"
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      }
    };


    return (
<>
<AnimatedPage>
{ loading ? <PageLoader isError={error} /> :
<div className="item-view-wrapper">
 <ItemLeft data={data} /> 
 <ItemRight data={data} /> 
 </div>
    }
    </AnimatedPage>
</>
)

    




}