import React from 'react'
import CountUp from 'react-countup';

export default function HomePageNumber({label, number}){


return (

    <div className='home-page-numbers-inner'>

        <div className='home-page-numbers-inner-value'><CountUp duration={5} end={number} /></div>
        <div className='home-page-numbers-inner-label'>{label}</div>
        </div>
)




}