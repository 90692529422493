import React from 'react'

import ListView from '../../ListView/ListView'

export default function ItemViewList({selectedTab, tabId, data}) {

return (

<span className={selectedTab == tabId ? "show-item-content item-listview" : "hide-item-content item-listview"}>




 <ListView type="tabs" data={data.related_items} />














</span>


)


}