import React, {useEffect, useState} from 'react' 
import { NavLink, useParams } from 'react-router-dom'
import ListViewImage from './ListViewImage'
import ListViewItemDoubleText from './ListViewItemDoubleText'
import ListViewEnd from './ListViewEnd'
export default function ListViewItem({data, url, type, customUrl}){
    const [error, setError] = useState(false);
const [finalUrl, setfinalUrl] = useState(url +  data.url_location + data.id);
const { parkId } = useParams()

useEffect(() => {

if(type === "tabs"){

    setfinalUrl(customUrl)
}

if(type === "past_area"){

   setfinalUrl("/area/" + data.id)
}
if(data.url_location === "/custom-page/"){

setfinalUrl("/custom-page/" + data.id)
}


if(type === "areas"){
    data.name = "Explore Park Areas"
    setfinalUrl("/areas/" + parkId)
}
},[])

    const handleImageError = (isError) => {


        setError(isError)
    }
return (

       <NavLink  to={finalUrl} > 
<div className='list-view-item'>

    { data.img_url !== null && error === false ?  <ListViewImage handleError={handleImageError} img={data.img_url}/> : null}

{ type !== undefined && type !== "tabs" && type !== "past_area" ? <div className='list-view-item-stacked-title'>
    <span className='list-view-item-stacked-title-primary'>Explore Park Areas</span> </div>
: <ListViewItemDoubleText data={data}/> }

{data.end !== null ? <ListViewEnd data={data} /> : null}

</div>
</NavLink>
)

}