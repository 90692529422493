import React, { useState } from "react";



export default function ItemListViewSortButtons({sort, queuesort}) {


    const [sortType, setSortType] = useState(1);
    const [queuesortType, setqueueSortType] = useState(1);
    const handleSort = (type) => {

setSortType(type)
sort(type);


    }

    const handlequeueSort = (type) => {

        setqueueSortType(type)
        queuesort(type);
        
        
            }


    return (
<span className="divider">
        
     { sortType === 2 ? <button className="item-list-view-button button-primary" onClick={() => handleSort(1)} >Sort A-Z</button> : <button className="item-list-view-button button-primary" onClick={() => handleSort(2)} >Sort Z-A</button> }
     { queuesortType === 2 ? <button className="item-list-view-button button-primary" onClick={() => handlequeueSort(1)} >Sort Short-Long</button> : <button className="item-list-view-button button-primary" onClick={() => handlequeueSort(2)} >Sort Long to Short</button> }
     
     </span>
    )

}