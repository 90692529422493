import React from 'react'
import ItemTabs from './ItemTabs'
import ItemGallery from '../ItemGallery/ItemGallery'

export default function ItemRight({data}){

    return (
<div className="item-view-right">
   
        <ItemTabs data={data} />
</div>
    )



}