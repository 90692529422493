import React from "react";


export default function ItemGalleryCloseItem({handleClick}){



return (



    <div className="item-gallery-item-fullscreen item-close" onClick={() => handleClick()}>
 <div className="item-gallery-item-close">Exit</div>
        
    </div>
)


}