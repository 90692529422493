import React from 'react'


export default function MobileMenuDropDownHeader({setdropdownOpen, dropdownOpen, data}){


   

    return (
       
       
            <div className="mobile-menu-header" onClick={() => setdropdownOpen(!dropdownOpen)}>
        <div className="mobile-menu-dropdown-title" >{data}</div>
            <div className='mobile-menu-dropdown-button' >
                
                {dropdownOpen ? 
                <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i> 

}</div>
            </div>
          

        
    )
}