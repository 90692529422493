import React from 'react';
import Markdown from 'react-markdown'

export default function ItemDescription({description}){
return (

<span className="item-attraction-description" >

    <Markdown>{description}</Markdown>
</span>

)
}