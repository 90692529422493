import React from 'react'
import ItemGallery from '../../ItemGallery/ItemGallery'


export default function ItemViewGallery({selectedTab, tabId}) {

return (

<span className={selectedTab == tabId ? "show-item-content item-stats" : "hide-item-content item-stats"}><ItemGallery /></span>


)


}