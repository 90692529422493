import React from 'react'
import ItemImage from './ItemImage';
import ItemBasicInfo from './ItemBasicInfo';
import ItemOpeningTimes from './ItemOpeningTimes';
import ItemKeyInfo from './ItemKeyInfo';
import ItemDescription from './ItemDescription';
import ItemTabs from './ItemTabs';
import ViewonQueueTimeHub from '../ViewonQueueTimeHub';
import ItemShowTimes from './ItemShowTimes';


export default function ItemLeft({data}){

    return (
<div className="item-desktop-left">
        <ItemImage data={data} />
<ItemBasicInfo data={data}/>
<ItemShowTimes data={data.showtimes} />
<ViewonQueueTimeHub id={data.temp_attractions_io} parkid={data.park_id} />
<ItemOpeningTimes hasOpeningTimes={data.has_opening_times} openingTime={data.opening_times}/>
<ItemKeyInfo data={data} />
<ItemDescription description={data.description}/>

</div>
    )



}