import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import ItemListViewItem from '../ItemListView/ItemlistViewItem';
import PageLoader from '../PageLoader';


export default function AreaLanding({}){

  
        const { itemId, type, parkId } = useParams()
        const [data, setData] = useState([]);
        const [urlsuffix, setUrlsuffix] = useState(type);
        const [filter, setFilter] = useState("*");
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(false);
        useEffect(() => {
    
          fetchData();
        }, []);
      
        const fetchData = async () => {
          try {
            const response = await fetch('https://attractionsbase.com/api/areas/?id=' + parkId);
            const jsonData = await response.json();
            setData(jsonData);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setError(true)
          }
        };


return (
   <>
    {loading ? <PageLoader isError={error} /> : <div className="item-list-view">
      
      
      
      
      <div className="item-list-view-container">
    
    {data.map(function(item,index){
    return (
  
    
    <ItemListViewItem type="area" key={index} data={item}/>
     
     
    
    )
    })
    }
    
    </div></div>
    }
 
    
 </>
    
    )
    
   
    
    
    
    
    
    }




