import React from 'react'


export default function FooterSocialMediaButtons({url, img}){



return (


<div className='social-media-button'>
    <a href={url}>
    <img className='social-media-button-img' src={img}/>
    
    </a>
    </div>


)


    
}